$productsArrayWrapper-img-width: auto;
$productsArrayWrapper-border-radius: $token-spacing-0 $token-spacing-8
  $token-spacing-0 $token-spacing-0 !important;
$productsArrayWrapper-img-border-radius: $token-spacing-0 $token-spacing-8
  $token-spacing-0 $token-spacing-0 !important;

.imageBlock {
  img {
    max-height: 150px;
    min-width: 100%;
  }
}
