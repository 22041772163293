div
  > div
  > div:not(:first-child)
  > div
  > .usb--checkbox-input:checked
  + label
  .usb--checkbox-svg
  svg {
  background: rgba(5, 88, 255, 1) !important;
}
