a.usb-link, span.usb-link {
  color: $site-blue !important;

  &.usb-link {
    color: $site-blue !important;
  }

  &.arrow {
    svg {
      fill: $site-blue !important;
    }
  }

  font-weight: $token-font-weight-reg !important;
}