$pageFooter-display: grid;
$pageFooter-block-margin: auto;
$pageFooter-text-align: left;
$pageFooter-block-spacing: 0px;
$pageFooter-pipeline-border-right: $token-border-width-default solid
  $token-background-primary;
$pageFooter-pipeline-right-spacing: $token-spacing-4;
$pageFooter-copyrightBlock-font-size: $token-spacing-14px;
$right-link-fontsize: $token-spacing-14px;
$right-link-spacing: $token-spacing-4;
$pageFooter-quicklinksListItem-a-color: #ffffff;

@media #{$usb-max-sm} {
  .quickLinksBlock {
    margin-top: 0px !important;
  }

  .copyrightBlock {
    ul {
      li {
        text-align: right !important;
      }
    }

    padding-top: 0px !important;
  }

  .quickLinksBlock {
    margin-left: 0px !important;
  }

  .pageFooter {
    .copyrightBlock {
      padding-top: 0px !important;
    }
  }
}
