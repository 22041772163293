/* Fonts */
html, body {
  font-family: $brand-font-base;
}
// iOS default font-size is 17px and accessible text needs this font setting to properly scale. Additionally, we need to reset the font-size to 16px from 17px.
@supports (font: -apple-system-body) {
  html {
    font: -apple-system-body;
  }
  body
  {
    font-size: 0.94rem;
  }
}

/*
This CSS resource incorporates links to font software which is the valuable copyrighted
property. You may not attempt to copy, install, redistribute, convert, modify or
reverse engineer this font software.
*/

// Font Sources
$font-files: (
  light: ( 
    weight: 300, 
    style: 'normal', 
    woff2: "Circular_USBANK_Beta-Light.woff2"
  ),
  light-italic: ( 
    weight: 300, 
    style: 'italic', 
    woff2: "Circular_USBANK_Beta-LightItalic.woff2"
  ),
  regular: ( 
    weight: 400, 
    style: 'normal', 
    woff2: "Circular_USBANK_Beta-Regular.woff2"
  ),
  regular-italic: ( 
    weight: 400, 
    style: 'italic', 
    woff2: "Circular_USBANK_Beta-Italic.woff2"
  ),
  book: ( 
    weight: 400, 
    style: 'normal', 
    woff2: "Circular_USBANK_Beta-Book.woff2"
  ),
  book-italic: ( 
    weight: 400, 
    style: 'italic', 
    woff2: "Circular_USBANK_Beta-BookItalic.woff2"
  ),
  medium: ( 
    weight: 500, 
    style: 'normal', 
    woff2: "Circular_USBANK_Beta-Medium.woff2"
  ),
  medium-italic: ( 
    weight: 500, 
    style: 'italic', 
    woff2: "Circular_USBANK_Beta-MediumItalic.woff2"
  ),
  bold: ( 
    weight: 700, 
    style: 'normal', 
    woff2: "Circular_USBANK_Beta-Bold.woff2"
  ),
  bold-italic: ( 
    weight: 700, 
    style: 'italic', 
    woff2: "Circular_USBANK_Beta-BoldItalic.woff2"
  ),
  black: ( 
    weight: 900, 
    style: 'normal', 
    woff2: "Circular_USBANK_Beta-Black.woff2"
  ),
  black-italic: ( 
    weight: 900, 
    style: 'italic', 
    woff2: "Circular_USBANK_Beta-BlackItalic.woff2"
  )

);

@each $font, $value in $font-files {
  @font-face{
    font-family:"Circular-USB";
    src:url("./library/fonts/testing/U.S.BANK_CircularBeta_17.01.23/Webfonts/#{ map-get($value, woff2 ) }") format("woff2");
    font-weight: #{ map-get($value, weight) };
    font-style: #{ map-get($value, style) };
    font-display:swap;
  }
}