@import "../custom-variables.scss";

$site-blue: rgb(5, 88, 255);
$button-blue: rgb(13, 80, 249);
$base-font-color: rgb(85, 85, 85);
$h2-blue: rgb(08, 75, 183);

// overwrite Shield Components variables here:
$brand-font-base: "Circular-USB", Arial;
$button-secondary-background-color: rgb(230, 230, 230);
$button-secondary-border-color: rgb(35, 90, 228);
$edp-button-loud-background-color: rgb(5, 88, 255);
$button-custom-delete-background-color: rgb(222, 22, 43);
$button-secondary-hover-text-color: rgb(0, 0, 0);
$button-secondary-active-text-color: rgb(0, 0, 0);
$stripe-background-color: $token-color-grey-20;
$heading2-text-color: rgb(8, 75, 183);
$token-background-gradient-interaction: rgb(0, 0, 0);
$token-background-brand-interaction: $site-blue;
$token-text-color-primary: $base-font-color;
$top-overlay-height:4.063rem; 
$link-basic-text-color: $site-blue;
$link-basic-light-text-color: $site-blue;
$link-inline-text-color: $site-blue;
$link-inline-light-text-color: $site-blue;
$link-subtle-text-color: $site-blue;
$link-subtle-light-text-color: $site-blue;
$link-arrow-text-color: $site-blue;
$link-arrow-light-text-color: $site-blue;
$link-back-text-color: $site-blue;
$link-back-light-text-color: $site-blue;
$link-basic-text-color: $site-blue;

$displayViews-h1-color: $heading2-text-color;
$productOverviewPage-h2-title-color: $heading2-text-color;
// overwrite CSS Modules variables here:
@import "pageLayout";
@import "pageHeader";
@import "pageFooter";
@import "desktopNavBar";
@import "mobileNavBar";
@import "fullPageBanner";
@import "link";
@import "heroBanner.scss";
@import "productsArrayWrapper";
@import "cookie";
@import "checkbox";

$stripe-background-color: rgb(247, 247, 250);
$headH2-font-color: $h2-blue;
$twoTierPageHeader-topHeight: $token-spacing-120px;
$twoTierPageHeader-height: $token-spacing-120px;
$twoTeirDesktopNavBar-menuSpacing: $token-spacing-46px;
$desktopNavBar-parentMenuLink-padding-mobile: $token-spacing-5-6px;