$heroBanner-twoColBannerSubHeader-font-size: $token-spacing-8;

.col div[data-testid="productImage"] {
  img {
    border-radius: 0 $token-spacing-8 0 0;
  }
}
// override Shield default style for login button
[class^="desktopNavBar_menuWrapper"] {
  .usb-button.button--highlight--heavy {
    background: #0558FF !important;
    border: 2px solid #0558FF !important;

    &:hover {
      background: #FFFFFF !important;
      border: 2px solid #0558FF !important;
      color: #0558FF;
      box-sizing: border-box;
    }
  }
}
// override Shield default style for primary button
[class^="fullPageBanner_buttons"] {
  .usb-button.button--highlight--heavy {
    background: #0558FF !important;
    border: 2px solid #0558FF !important;

    &:hover {
      background: #FFFFFF !important;
      border: 2px solid #0558FF !important;
      color: #0558FF;
      box-sizing: border-box;
    }
  }
}
// override Shield default style for secondary button
[class^="fullPageBanner_buttons"] {
  .usb-button.button--standard--subtle {
    background: #E6E6E6 !important;
    color: #2C2C2C !important;
    border: 2px solid #E6E6E6 !important;

    &:hover {
      background: #FFFFFF !important;
      border: 2px solid #000000 !important;
      color: #000000;
      box-sizing: border-box;
    }
  }
}