.white-background {
  background: rgb(255, 255, 255) !important;
}

.shared-btn-style {
  background: $edp-button-loud-background-color !important;

  &:hover,
  &:active {
    @extend .white-background;

    color: $button-blue !important;
    border: solid 2px $button-blue !important;

    &::before {
      border: none !important;
    }
  }
}

.usb-button {
  border-radius: $token-border-radius-0 !important;
  font-family: $brand-font-base !important;

  &.button {
    &--primary {
      @extend .shared-btn-style;

      background: $edp-button-loud-background-color !important;
      border: solid 2px $button-blue !important;

      &[data-testid="onLogoutClick"] {
        background: rgb(255, 255, 255) !important;
        border: none !important;
      }

      &#logoff-link {
        text-decoration: none !important;

        &:hover {
          color: $token-background-brand-interaction !important;
          text-decoration: underline !important;
        }
      }
    }

    &--secondary {
      @extend .shared-btn-style;
      
      border-width: $token-border-width-0 !important;
      border-color: $button-secondary-border-color !important;
      background: $button-secondary-background-color !important;
      color: rgb(0, 0, 0) !important;
      border: solid 2px $button-secondary-background-color !important;

      &:hover {
        color: $button-secondary-hover-text-color !important;

        &::before {
          border: none !important;
        }
      }

      &:active {
        color: $button-secondary-active-text-color !important;
      }

      &:hover,
      &:active {
        @extend .white-background;
        
        border: solid 2px rgb(0, 0, 0) !important;
      }
    }

    &--loud {
      background: $edp-button-loud-background-color !important;

      &:hover {
        @extend .white-background;
      }

      &:active {
        @extend .white-background;
      }
    }

    &--custom-delete {
      background: $button-custom-delete-background-color !important;

      &:hover {
        background: #{$button-loud-hover-background-color} !important;
      }

      &:active {
        background: #{$button-loud-active-background-color} !important;
      }
    }

    &--loud,
    &--button-default {
      @extend .shared-btn-style;

      border: solid 2px $button-blue !important;
    }
  }

  &:disabled {
    background: #{$token-disabled-element-color} !important;
    color: $token-text-color-secondary !important;
    cursor: not-allowed !important;
    border-color: $token-disabled-element-color !important;
  }
}
